export const SET_FILTER_OPTIONS_DATA = "SET_FILTER_OPTIONS_DATA";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SET_COUNTRY_OPTION = "SET_COUNTRY_OPTION";
export const SET_CITY_OPTION = "SET_CITY_OPTION";
export const SET_COMPANY_OPTION = "SET_COMPANY_OPTION";
export const IS_BURGER = "IS_BURGER";
export const IS_MOBILE = "IS_MOBILE";
export const BACKGROUND_BTN = "BACKGROUND_BTN";
export const SWITCH_BACKGROUND_ON = " SWITCH_BACKGROUND_ON"
export const SWITCH_BACKGROUND_OFF = " SWITCH_BACKGROUND_OFF"
